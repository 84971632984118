.main-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 36px;
  background: transparent;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  @media screen and (max-width: 992px) {
    width: 36px;
    height: 34px;
    &--position {
      position: static;
      height: auto;
    }
  }
  .custom-input {
    height: 38px;
  }
  &__burger {
    display: none;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    height: 2px;
    width: 80%;
    border-radius: 9px;
    opacity: 1;
    background: black;
    @media screen and (max-width: 992px) {
      display: block;
      position: absolute;
    }
    &:nth-child(1) {
      top: 10px;
    }
    &:nth-child(2) {
      top: 16px;
    }
    &:nth-child(3) {
      top: 22px;
    }
  }
  &__collapse {
    border: none;
    outline: none;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: transparent;
    @media screen and (max-width: 992px) {
      width: 36px;
      height: 36px;
      position: relative;
      svg {
        display: none;
      }
    }
    &--hidden {
      @media screen and (max-width: 992px) {
        display: none;
      }
    }
  }
  &__menu {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    grid-gap: 5px;
    padding: 5px;
    background: #ffffff;
    height: 100%;
    box-shadow: 0 3px 25px rgb(0 0 0 / 6%);
    @media screen and (max-width: 992px) {
      flex-direction: column;
      grid-gap: 25px;
      box-shadow: none;
      padding: 0;
    }
  }
  &__visible {
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: flex-start;
    grid-gap: 45px;
    transition: transform 1s;
    margin-right: 60px;
    svg {
      width: 20px;
      height: 20px;
    }
    .menu-link {
      display: flex;
      align-items: center;
      grid-gap: 5px;
      cursor: pointer;
      font-weight: 500;
      &--hide_large {
        display: none;
        @media screen and (max-width: 992px) {
          display: flex;
          font-weight: 600;
        }
      }
    }
    @media screen and (min-width: 992px) and (max-width: 1340px) {
      grid-gap: 20px;
      margin-right: 20px;
      font-size: 14px;
    }
    @media screen and (max-width: 992px) {
      flex-direction: column;
      grid-gap: 35px;
      transition: transform 0.5s;
      padding: 0 10px 10px 10px;
      &--hide {
        display: none;
      }
      &--hide_content {
        display: none;
      }
      &--log {
        display: none;
      }
    }
  }
  .enter-done {
    .main-nav__visible {
      transform: translateX(-10000px);
      position: absolute;
      top: -2000px;
    }
  }
  .exit-done {
    .main-nav__burger {
      &:nth-child(1) {
        transform: rotate(135deg);
        top: 18px;
      }
      &:nth-child(2) {
        opacity: 0;
        left: -60px;
      }
      &:nth-child(3) {
        transform: rotate(-135deg);
        top: 18px;
      }
    }
    .main-nav__visible {
      transform: translateX(0);
    }
  }
  .profile {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    .profile-name {
    }
    .profile-avatar {
      height: 30px;
      width: 30px;
      display: flex;
      align-content: center;
      justify-content: center;
      border-radius: 50%;
      background: #a8a5a5;
      margin-right: 40px;
      cursor: pointer;
    }
    svg {
      width: 15px;
      fill: white;
    }
    @media screen and (max-width: 992px) {
      display: none;
    }
    @media screen and (min-width: 992px) and (max-width: 1340px) {
      .profile-avatar {
        margin-right: 10px;
      }
    }
  }

  .collapse {
    width: 20px;
    height: 20px;
    transform: rotate(270deg);
    transition: transform 0.5s ease;
  }
  .rotate {
    transform: rotate(90deg);
  }
}
.profile-mini-modal {
  position: fixed;
  max-width: 280px;
  width: 100%;
  top: 40px;
  right: 40px;
  background-color: white;
  box-shadow: 0 0 0 0.5px #000;
  padding: 11px 9px;
  &__logout {
    padding-top: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 15px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      opacity: 0.4;
      svg {
        transform: scale(1.1);
      }
    }
    svg {
      width: 25px;
    }
  }
  &__email {
    padding-bottom: 7px;
    text-align: center;
    border-bottom: 1px solid grey;
  }
  &__int_wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    margin-bottom: 15px;
    .custom-input__topright-err {
      &:first-child {
        top: -28px;
      }
    }
    @media screen and (max-width: 992px) {
      .custom-input {
        margin-bottom: 20px;
      }
    }
  }
  @media screen and (max-width: 992px) {
    position: absolute;
    width: 100%;
    max-width: unset;
    right: 0;
    top: 60px;
    box-shadow: unset;
    padding: 0 15px;
    &--hide {
      display: none;
    }
    &__logout {
      font-size: 16px;
      justify-content: flex-start;
    }
    &__email {
      text-align: left;
      border-bottom: none;
      opacity: 0.5;
      font-size: 14px;
    }
  }
}
