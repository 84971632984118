@import "./variables.scss";

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: $black;

&::-webkit-scrollbar {
   width: 8px;
 }

&::-webkit-scrollbar-track-piece {
   border-radius: 20px;
   background: linear-gradient(to right, #fff 20%, #8C8D8B 20%, #8C8D8B 80%, #ffff 80%);
 }

&::-webkit-scrollbar-thumb {
   border-radius: 20px;
   width: 8px;
   max-height: 21px;
   min-height: 21px;
   height: 21px;
  background-color: #8C8D8B;
 }
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
#root{
  height: 100%;
}