.space {
  width: 100%;
  height: 100%;
  @media screen and (max-width: 992px) {
    &--hide {
      display: none;
    }
  }
  &__iframe-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: hidden;
  }

  &__iframe {
    width: 100%;
    height: 100%;
  }
  &__small_height {
    @media screen and (max-width: 576px) {
      height: calc(100%);
    }
  }
  &__mini-iframe {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 300px;
    height: 200px;
    z-index: 1;
    @media screen and (max-width: 576px) {
      width: 100%;
      opacity: 0;
      pointer-events: none;
    }
    &--hover {
      width: 50vw;
      height: 50vh;
    }
  }
  &__iframe--hide {
    opacity: 0;
    pointer-events: none;
  }
}
.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-transform-component {
  position: relative;
}
.minimap-space {
  max-width: 512px;
  max-height: 512px;
  background: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;
  .minimap-space__img {
    max-width: 512px;
  }
  // transition: all 0.5s ease-in-out;

  &:hover {
    @media screen and (min-width: 1280px) {
      max-width: 1024px;
      max-height: calc(100vh - 20vh);
      width: 724px;
      height: 724px;
    }
  }
  @media screen and (max-width: 576px) {
    width: 100%;
    height: 50vh;
    max-height: 50vh;
    display: flex;
    align-items: center;
    .minimap-space__controls {
      top: 0;
    }
    .minimap-space__image-content {
      position: relative;
      height: 100%;
    }
  }
  @media screen and (max-width: 968px) {
    &:hover {
      width: 100%;
      .minimap-sweeps {
      }
    }
  }
  &__anchor {
    position: absolute;
    width: 12px;
    height: 12px;
    margin-left: -4px;
    margin-bottom: -5px;
    transform-origin: 50% 50%;
    transition: all 0.6s linear, transform 0ms;

    &--big {
      margin-left: -5px;
      width: 25px;
      height: 25px;
    }
  }
  &__controls {
    display: flex;
    width: min-content;
    padding: 10px 0 0 10px;
    grid-gap: 6px;
    position: absolute;
    background: transparent;
    z-index: 2;
    &--hide {
      display: none;
      &-big {
        display: none;
      }
    }
  }
  &__btn {
  }
  &__img {
    max-width: 100%;
    max-height: 100vh;
    object-fit: fill;
    object-position: center;
    @media screen and (max-width: 576px) {
      max-height: 50vh;
    }
  }
  &__image-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.minimap-space-full {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .minimap-space__image-content {
    max-width: 100%;
    max-height: 100%;
    //width: auto;
    position: relative;
    //height: auto;
  }
  .react-transform-wrapper {
    width: 100vw;
    height: 100vh;
  }
  .tools {
    left: 10px;
  }
  .minimap-space__img {
    height: auto !important;
  }
  /*  @media screen and (max-width: 576px){
    height: calc(100vh - 200px);
  }*/
}
.minimap-space-hide {
  display: none;
}
.iframe-space {
  &__hide {
    display: none;
  }
  &__show {
    width: min-content;
    height: 20px;
    background: transparent;
    position: absolute;
    right: 228px;
    bottom: 170px;
    z-index: 2;
    display: flex;
    grid-gap: 6px;
    @media screen and (max-width: 576px) {
      right: 10px;
      top: 10px;
    }
    &--hover {
      right: 46vw;
      bottom: 46vh;
    }
  }
}

.space__iframe-container:has(.space__mini-iframe--hover) .iframe-space__show {
  right: 46%; /* Ajusta este valor según sea necesario */
  bottom: 46%; /* Ajusta este valor según sea necesario */
}

.space-show-minimap {
  width: 44px;
  height: 40px;
  position: absolute;
  right: 0;
  bottom: 30px;
  display: grid;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  outline: none;
  border: none;
  svg {
    width: 22px;
  }
  &:hover {
    &:after {
      content: attr(data-tooltip-content);
      position: absolute;
      right: 46px;
      width: max-content;
      top: 50%;
      padding: 5px;
      transform: translateY(-50%);
      background: #2e2b2b;
      color: white;
    }
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  &--floor {
    bottom: 80px;
    &:hover {
      &:after {
        content: attr(data-tooltip-content);
        position: absolute;
        right: 46px;
        width: max-content;
        top: 50%;
        padding: 5px;
        transform: translateY(-50%);
        background: #2e2b2b;
        color: white;
      }
    }
  }
  &--active {
    background: rgba(0, 0, 0, 0.8);
    box-shadow: none;
    // svg {
    //   fill: #8dc63f;
    // }
    &:hover {
      &:after {
        content: attr(data-tooltip-content);
        position: absolute;
        right: 46px;
        width: max-content;
        top: 50%;
        padding: 5px;
        transform: translateY(-50%);
        background: #2e2b2b;
        color: white;
      }
    }
  }
}
.minimap-space__btn {
  width: 28px;
  height: 28px;
  background: #ffffff;
  outline: none;
  border: none;
  display: grid;
  place-content: center;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  svg {
    width: 10px;
    height: 10px;
  }
  &--flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.space__wrapper_floor {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  left: 10px;
  padding: 10px 16px;
  @media screen and (max-width: 576px) {
    right: 10px;
    left: initial;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
      rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  }
}
.space_floor {
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
.minimap-sweeps {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  &--small {
    top: unset;
    bottom: unset;
    height: 100%;
  }
}
.minimap-sweep {
  width: 16px;
  height: 16px;
  //background-color: #DD4E67;
  border: 2px solid #dd4e67;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  opacity: 0.7;
  @media screen and (max-width: 1360px) {
    width: 8px;
    height: 8px;
    &:after {
      width: 2px !important;
      height: 2px !important;
    }
  }
  @media screen and (max-width: 568px) {
    width: 4px;
    height: 4px;
    &:after {
      width: 2px !important;
      height: 2px !important;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #dd4e67;
  }
  &:hover {
    transform: scale(1.5);
    opacity: 1;
  }
  &--small {
    width: 6px;
    height: 6px;
    border: 1px solid #dd4e67;
    &:after {
      width: 2px;
      height: 2px;
    }
  }
}
.minimap-poi {
  width: 15px;
  height: 15px;
  position: absolute;
  cursor: pointer;
  &:hover {
    transform: scale(1.5);
  }
  &--small {
    width: 10px;
    height: 10px;
  }
}
.tools {
  position: absolute;
  left: 0;
  bottom: 2%;
  z-index: 1;
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
