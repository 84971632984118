.custom-checkbox {
    display: flex;
    align-items: center;
    font-size: 13px;

    .checkbox {
        width: 17px;
        height: 17px;
        box-shadow: 0px 3px 8px #0000000a;
        border: 1px solid #1a1a1c17;
        border-radius: 3px;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-bottom: 3px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .label {
        margin-left: 8px;
        word-break: break-word;
        &--on{
            cursor: pointer;
            &:hover{
                font-weight: 700;
            }
        }
        &--bold{
            font-weight: 700;
        }
    }
}
