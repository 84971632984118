.main{
  width: 100%;
  height: 100%;
}
.profile-back{
  align-items: center;
  justify-content: flex-start;
  grid-gap: 7px;
  font-weight: 500;
  font-size: 16px;
  display: none;
  margin-bottom: 17px;
  svg{
    fill: #2E2B2B;
    width: 16px;
  }
  @media screen and (max-width: 992px){
    display: flex;
  }
  &--padding{
    padding: 0 15px;
  }
  &--margin_none{
    margin-bottom: 0;
  }
}
.error{
  background: #2e2b2b;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 26px;
}