.close-tile {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -30px;
  right: -15px;
  border: none;
  border-radius: 50;
  color: #000;
  flex-shrink: 0;
  outline: none;
  user-select: none;
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
  @media screen and (max-width: 992px) {
    display: none;
  }
}
.tile-modal {
  width: 526px;
  padding: 30px 23px;
  background: white;
  z-index: 4;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 75vh;
  overflow: auto;
  //border-radius: 4px;
  //box-shadow: rgb(33 33 33) 0px 0px 15px 5px;
  perspective: 1000px;
  &-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    -moz-backface-visibility: hidden;
  }
  &-front {
    backface-visibility: hidden;
  }
  &-back {
    backface-visibility: hidden;
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
    height: 92%;
  }
  &__flip {
    .tile-modal-inner {
      transform: rotateY(180deg);
    }
  }
  &__help {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 12px;
    margin-top: 15px;
  }
  &__switcher-text {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    color: #2e2b2b;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: 0.2s linear 0.2s;
    &:hover {
      transform: scale(1.2);
      color: #8dc63f;
    }
  }
  &__switcher {
    display: flex;
    align-items: center;
    input:checked + .slider {
      background-color: #8dc63f;
    }
  }
  &-type {
    .custom-modal__container {
      background: white;
      width: 765px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: auto;
      max-height: 90vh;
      border-radius: 0;
      @media screen and (max-width: 992px) {
        width: 95%;
      }
    }
    .custom-modal__header {
      background: transparent;
      position: absolute;
      padding: 0;
      right: 4px;
      .custom-modal__title {
        display: none;
      }
    }
    .custom-modal__spacer {
      display: none;
    }
    .custom-modal__footer {
      display: none;
    }
    .custom-modal__main {
      padding-bottom: 0;
      margin-bottom: 0;
      padding-top: 30px;
    }
    .custom-modal__footer {
      padding-bottom: 0;
    }
    .custom-dropdown {
      .select {
        padding-top: 10px;
        position: static;
        background-color: #f6f7f8;
        border-top: 1px solid #707070;
        max-height: max-content;
        .select__option {
          font-size: 13px;
          font-weight: 400;
          opacity: 0.5;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .confirm-modal {
      .custom-modal_animated {
        z-index: 7;
      }
      .custom-modal__container {
        width: 446px;
      }
      .custom-modal__main {
        padding-bottom: 30px;
      }
    }
  }
  &--subtitle {
    color: #2e2b2b;
    opacity: 0.5;
    font-size: 13px;
    margin-bottom: 8px;
  }
  &--main_text {
    width: 100%;
  }
  &__field {
    margin-bottom: 23px;
    &--size {
      display: flex;
      flex-direction: column;
      grid-gap: 16px;
    }
  }
  &__upload-img {
    margin-top: 10px;
    width: 100%;
    height: 432px;
  }
  &__upload-image {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    object-position: center;
    border-radius: 4px;
  }
  &__upload-audio {
    height: 150px;
  }
  &__audio {
    height: 100%;
    box-shadow: none;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.63);
    .rhap_play-pause-button {
      height: 44px;
    }
    .rhap_volume-button {
      height: 28px;
    }
    .rhap_volume-controls {
      width: 100px;
      margin-right: 5px;
      flex: 0 1 auto;
      flex-shrink: 0;
    }
    .rhap_main-controls {
      width: 100%;
      padding-left: 100px;
    }
  }
  &__checkbox {
    display: flex;
    align-items: center;
    grid-gap: 72px;
  }
  &--mb {
    margin-bottom: 20px;
  }
  &__btn {
    width: 52px;
    height: 52px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 3px 15px #0000000f;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      width: 20px;
    }
    &:hover {
      border: 1px solid rgba(201, 192, 192, 0.5);
      transform: scale(1.2);
      transition: 0.3s;
    }
  }
  &__hero {
    width: 100%;
    height: 150px;
    //box-shadow: 0px 3px 15px #00000008;
    overflow: hidden;
    //border-radius: 4px;
    background: #c6e29f;
    position: relative;
    &:hover {
      .tile-modal__play {
        transform: translate(-50%, -50%) scale(1.2);
      }
      .tile-modal__item_type {
        font-size: 20px;
        transition: font-size 0.2s;
      }
    }
    &__btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        width: 26px;
        fill: #8dc63f;
      }
      &:hover {
        transform: translate(-50%, -50%) scale(1.2);
        border: 1px solid rgba(201, 192, 192, 0.5);
        transition: 0.3s;
      }
    }
    &--hide {
      display: none;
    }
  }
  &__tile {
    display: grid;
    grid-gap: 15px;
    margin-top: 16px;
    grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr));
  }
  &__item_tile {
    height: 60px;
    background-color: black;
    opacity: 0.9;
    //border-radius: 4px;
    cursor: pointer;
    position: relative;
    // box-shadow: 0 0 0 1px black;
    overflow: hidden;
    &:hover {
      opacity: 1;
    }
  }
  &__item_type {
    display: block;
    margin: 0 auto;
    text-transform: capitalize;
    color: #2e2b2b;
    width: 40%;
    text-align: center;
    border-radius: 0 0 10px 10px;
    background: rgb(255 255 255 / 90%);
    &--top {
      padding-top: 20px;
    }
  }
  &__item-create {
    background: #c6e29f;
    border-radius: 4px;
    color: #2e2b2b;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 60px;
    &:hover {
      box-shadow: inset 0px 0px 1px 2px rgba(46, 43, 43, 1);
    }
  }
  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-position: center;
    border-radius: 20px;
    height: 35px;
    width: 30px;
    overflow: hidden;
    svg {
      fill: white;
      width: 35px;
      border-radius: 5px;
      stroke: black;
    }
  }
  @media screen and (max-width: 992px) {
    position: static;
    width: auto;
    transform: unset;
    box-shadow: unset;
    height: auto;
    max-height: max-content;
    margin-top: 20px;
    padding-top: 0;
  }
}

.type-modal-type--content {
  .custom-dropdown {
    .select {
      position: absolute;
      border-top: none;
      background-color: #fff;
      z-index: 3;
    }
  }
}
.tile-modal-type__image {
  max-width: 100%;
  height: auto;
  max-height: 432px;
  //border-radius: 4px;
  border: none;
  display: flex;
  margin: 0 auto;
  margin-bottom: 23px;
}
.tile-modal-type__iframe {
  width: 100%;
  height: 432px;
  //border-radius: 4px;
  border: none;
  margin-bottom: 23px;
}
.tile-link {
  display: flex;
}
.tile-section {
  width: 100%;
  height: 100%;
  &__title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    word-break: break-word;
    //background: rgba(0,0,0,0.1);
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    width: 100%;
  }
  &__simple-header {
    font-weight: 600;
    font-size: 16px;
  }
  &--text {
    padding: 10px;
    overflow: auto;
    * {
      color: inherit;
    }
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-track-piece {
      background: #cbcbcc;
    }
    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
  }
  &--title {
    text-align: center;
    display: block;
    word-wrap: break-word;
    overflow: auto;
    text-decoration: none;
  }
  &--audio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .rhap_container {
      height: auto;
      background: inherit;
      box-shadow: none;
      .rhap_controls-section {
        margin-top: 0;
      }
    }
  }
  &--point {
    pointer-events: none;
  }
  &--audio_small {
    .rhap_main-controls {
      padding-left: 0;
    }
  }
}
.react-player {
  video {
    object-fit: fill;
  }
}
