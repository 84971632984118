.space-information {
  position: fixed;
  width: 400px;
  background: white;
  z-index: 2;
  top: 50px;
  left: 30px;
  font-size: 14px;
  &__header {
    font-size: 12px;
  }
  &__place {
    font-weight: 500;
  }
  &__head {
    padding: 9px 12px 10px 12px;
    border-bottom: 1px solid grey;
  }
  &__body {
    padding-top: 10px;
    grid-gap: 15px;
    padding: 9px 12px 18px 12px;
    svg {
      width: 14px;
      height: 18px;
    }
  }
  &__wrap {
    display: flex;
    grid-gap: 15px;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    word-break: keep-all;
    overflow-wrap: break-word;
  }
  @media screen and (max-width: 992px) {
    position: static;
    width: 100%;
    box-shadow: unset;
    margin-top: 20px;
    &__head {
      padding-top: 0;
    }
  }
}
.space-column {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.space-share {
  position: fixed;
  width: 300px;
  background: white;
  z-index: 2;
  top: 36px;
  left: 965px;
  padding: 15px;
  @media screen and (min-width: 992px) and (max-width: 1340px) {
    left: 740px;
  }
  &--position_tile {
    left: 865px;
    @media screen and (min-width: 992px) and (max-width: 1340px) {
      left: 660px;
    }
  }
  &--position_conference {
    left: 770px;
    @media screen and (min-width: 992px) and (max-width: 1340px) {
      left: 580px;
    }
  }
  &--all {
    left: 670px;
    @media screen and (min-width: 992px) and (max-width: 1340px) {
      left: 500px;
    }
  }
  &--poi {
    left: 560px;
    @media screen and (min-width: 992px) and (max-width: 1340px) {
      left: 430px;
    }
  }
  &--poi_tiles {
    left: 670px;
    @media screen and (min-width: 992px) and (max-width: 1340px) {
      left: 500px;
    }
  }
  &--poi_tiles_conf {
    left: 865px;
    @media screen and (min-width: 992px) and (max-width: 1340px) {
      left: 660px;
    }
  }

  @media screen and (max-width: 992px) {
    position: static;
    width: 100%;
    box-shadow: unset;
    margin-top: 20px;
    padding-top: 0;
  }
  &__wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    grid-gap: 15px;
  }
  &__hero {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
  }
  &-title {
    font-weight: 500;
  }
  &__main {
    display: flex;
    width: 100%;
    height: 38px;
    .custom-input__input {
      border: none;
      cursor: text !important;
    }
  }
  &__social {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 16px;
  }
  &__icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #969595;
    margin-bottom: 15px;
    cursor: pointer;
    svg {
      max-width: 16px;
      max-height: 16px;
      width: auto;
      fill: white;
    }
    &:hover {
      svg {
        transform: scale(1.1);
      }
    }
  }
  &__btn {
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2e2b2b;
    border-radius: 0px 4px 4px 0px;
    border: none;
    outline: none;
    color: white;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:after {
      content: "";
      display: block;
      position: absolute;
      padding-top: 300%;
      padding-left: 350%;
      opacity: 0;
      transition: all 0.8s;
    }
    &:active:after {
      padding: 0;
      margin: 0;
      opacity: 1;
      transition: 0s;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}
.space-poi {
  position: fixed;
  width: 368px;
  background: white;
  z-index: 2;
  top: 36px;
  left: 275px;
  max-height: 80vh;
  overflow: auto;
  @media screen and (min-width: 992px) and (max-width: 1340px) {
    left: 220px;
  }
  @media screen and (max-width: 992px) {
    position: static;
    width: 100%;
    box-shadow: unset;
    margin-top: 20px;
    max-height: unset;
    padding-bottom: 10px;
    .space-poi__head {
      padding: 0;
    }
    .space-poi__search {
      padding: 0 12px;
    }
    .space-poi__all {
      padding: 0 12px 20px 12px;
    }
    .space-poi__poi {
      padding: 0 12px;
    }
    .custom-checkbox {
      font-size: 14px;
    }
  }
  &__icon {
    width: 14px;
    height: 14px;
  }
  &__check-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &__search {
    height: 38px;
  }
  .custom-input__input {
    border: none;
    outline: none;
  }
  &__all {
    margin-top: 12px;
    padding-bottom: 20px;
    border-bottom: 1px solid grey;
  }
  &__head {
    padding: 12px 12px 18px 12px;
  }
  &__poi {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    grid-gap: 13px;
  }
  &__category {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #2e2b2b;
    font-size: 14px;
    font-weight: bold;
    word-break: break-word;
  }
}
.space-conference {
  position: fixed;
  width: 216px;
  background: white;
  z-index: 2;
  top: 36px;
  left: 770px;
  font-size: 14px;
  padding: 19px;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  align-items: flex-start;
  @media screen and (min-width: 992px) and (max-width: 1340px) {
    left: 580px;
  }
  &--position {
    left: 660px;
    @media screen and (min-width: 992px) and (max-width: 1340px) {
      left: 500px;
    }
  }
  &--right_position {
    left: 560px;
    @media screen and (min-width: 992px) and (max-width: 1340px) {
      left: 430px;
    }
  }
  @media screen and (max-width: 992px) {
    position: static;
    width: 100%;
    box-shadow: unset;
    margin-top: 20px;
    padding-top: 0;
  }
  &__link {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    font-size: 14px;
    color: #2e2b2b;
    cursor: pointer;
    text-decoration: none;
  }
  &__wrap-icon {
    width: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      max-width: 100%;
      max-height: 18px;
    }
  }
}
