.point {
  position: fixed;
  z-index: 1;
  border-radius: 3px;
  background-color: #ffffff;
  left: 60px;
  top: 60px;
  max-height: 70vh;
  overflow: auto;
  @media screen and (max-width: 576px) {
    width: 100vw !important;
    height: 100vh !important;
    max-width: 100vw !important;
    max-height: 100vh !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
  }
  word-break: break-word;
  //box-shadow: 0 0 0 0.5px #000;
  max-height: calc(100vh - 140px);
  /*&:after{
    content: '';
    position: absolute;
    border: 8px solid transparent;
    border-left: 9px solid #ffffff;
    box-shadow: 0px 3px 15px #0000000F;
    opacity: 0.5;
    width: 0;
    height: 0;
    right: -17px;
    top: 15px;
  }*/
  &--shadow {
    box-shadow: 0 3px 15px rgb(0 0 0 / 32%);
    @media screen and (max-width: 576px) {
      display: block;
    }
  }
  &--resible {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    padding: 25px 15px 15px 15px;
    &::-webkit-scrollbar:horizontal {
      height: 8px;
      width: 8px;
    }
  }
  &__resize {
    right: -5px;
    bottom: -5px;
    cursor: nwse-resize;
    width: 10px;
    height: 10px;
    border-radius: 50%; /*magic to turn square into circle*/
    background: white;
    border: 1px solid #4286f4;
    position: absolute;
  }
  &__head {
    display: flex;
    align-items: center;
  }
  &__icon {
    flex-shrink: 0;
    width: 34px;
    height: 34px;
    margin-right: 20px;
    object-fit: contain;
    object-position: center;
  }
  &__default-icon {
    width: 34px;
    height: 34px;
    margin-right: 20px;
    flex-shrink: 0;
    circle {
      fill: inherit;
    }
  }
  &__title {
    color: #2e2b2b;
    font-size: 18px;
  }
  &__main {
    margin-top: 10px;
    p {
      min-height: 25px;
      margin: 10px 0;
    }
    &::-webkit-scrollbar:horizontal {
      height: 8px;
      width: 8px;
    }
    &::-webkit-scrollbar-track-piece:horizontal {
      border-radius: 5px;
      background: #cbcbcc;
      background: linear-gradient(
        to bottom,
        #fff 0,
        #fff 30%,
        #8c8d8b 20%,
        #8c8d8b 70%,
        #fff 70%,
        #fff 100%
      );
    }
    &::-webkit-scrollbar-thumb:horizontal {
      background: #8c8d8b;
      width: 4px;
      //background-image: linear-gradient(to bottom, #cbcbcc 10%, #8dc63f 10% 60%, #cbcbcc 60%);
    }
    @media screen and (max-width: 576px) {
      height: auto;
    }
  }
  &__media {
    margin-top: 10px;
    .img {
      object-position: center;
      object-fit: contain;
      width: 100%;
    }
    .react-wrapper {
    }
  }
  &--position {
    left: 5%;
    top: 20%;
  }
  &--more_right {
    left: 41.5%;
  }
  &__close {
    position: absolute;
    right: 4px;
    top: 0;
    border: none;
    outline: none;
    user-select: none;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  @media screen and (max-width: 1340px) {
    max-width: 60vw;
    &--resible {
      &::-webkit-scrollbar:horizontal {
        height: 8px;
        width: 8px;
      }
      &::-webkit-scrollbar-track-piece:horizontal {
        border-radius: 5px;
        background: #cbcbcc;
        background: linear-gradient(
          to bottom,
          #fff 0,
          #fff 30%,
          #8c8d8b 20%,
          #8c8d8b 70%,
          #fff 70%,
          #fff 100%
        );
      }
      &::-webkit-scrollbar-thumb:horizontal {
        background: #8c8d8b;
        width: 4px;
        //background-image: linear-gradient(to bottom, #cbcbcc 10%, #8dc63f 10% 60%, #cbcbcc 60%);
      }
    }
    &__main {
      img {
        display: block;
        max-width: 100%;
      }
    }
  }
  .point__main p a {
    color: #007bff;
    text-decoration: underline;
  }
}
